/* .tilt-card {
  transition: transform 0.1s ease;
  transform-style: preserve-3d;
}

.tilt-card:hover {
  transform: scale(1.05);
} */
/* .animated-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  perspective: 1000px;
}

.animated-card:hover {
  transform: scale(1.05) translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.animated-card::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 10%,
    transparent 10%
  );
  transform: translateX(-50%) translateY(-50%);
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.animated-card:hover::after {
  opacity: 1;
}

@keyframes moveBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-card:hover::after {
  animation: moveBackground 1.5s ease infinite;
} */
/* .animated-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.animated-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.animated-card::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, #ff8008, transparent);
  transition: all 0.3s ease;
  transform: translate(0, 0);
  pointer-events: none;
  z-index: 0;
}

.animated-card:hover::before {
  background: radial-gradient(circle at center, #ff8008, transparent);
} */
.animated-card {
  position: relative;
  overflow: hidden;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

.animated-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.animated-card::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  transition: all 0.3s ease;
  transform: translate(0, 0);
  pointer-events: none;
  z-index: 0;
}

.animated-card:hover::before {
  background: radial-gradient(circle at center, #f2f6ff, transparent);
  /* #251709 */
}
