.font-aeonik {
  font-family: "Montserrat", sans-serif;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.font-open-sans {
  font-family: "Open Sans", sans-serif;
}

.transition-max-height {
  transition: max-height 0.5s ease-out;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.accordion-content.expanded {
  max-height: 500px;
}

.main-color {
  color: #e1feeb;
}

summary {
  cursor: pointer;
}

details[open] summary .summary-text {
  color: white !important;
}

details[open] summary .main-color {
  color: white !important;
}

details[open] summary {
  background-color: #122b47;
  color: #ffffff;
}
details[open] .xog {
  background-color: #122b47;
  color: #ffffff;
}
details[open] .xog .xgtxt {
  background-color: #122b47;
  color: #ffffff;
}

.achievement-badge {
  position: absolute;
  bottom: -60px;
  right: 20px;
  background: #122b47;
  color: #ffffff;
  padding: 15px 80px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.achievement-badge img {
  width: 60px;
  margin-top: -30px;
  margin-right: -20px;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #def1e3;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  background: #b7e6c3;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  background: #40c461;
  opacity: 1;
}

.section-with-z-index {
  z-index: 1;
  position: relative;
}
/* carousel.css */
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d1fae5; /* Light green */
  margin: 0 5px; /* Adjust spacing between dots */
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #10b981; /* Darker green for the active dot */
}
