.content ul,
.content ol {
  margin-left: 20px;
  padding-left: 20px;
}

.content ul {
  list-style-type: disc;
}

.content ol {
  list-style-type: decimal;
}

.content li {
  margin-bottom: 10px;
}
