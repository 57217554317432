.service-card {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.service-card:hover {
  background-color: #344054;
  color: #ffffff;
}

.service-card:hover h3,
.service-card:hover p,
.service-card:hover a {
  color: #ffffff;
}
.service-card {
  background-color: #ffffff; /* White background */
  transition: background-color 0.3s ease;
}

.service-card.taxi:hover {
  background-color: #e3f2fd; /* Light blue background on hover */
}

.service-card.suuq:hover {
  background-color: #e8f5e9; /* Light green background on hover */
}

.service-card.food:hover {
  background-color: #fff3e0; /* Light orange background on hover */
}

.service-card.delivery:hover {
  background-color: #fce4ec; /* Light pink background on hover */
}

.service-card.supermarket:hover {
  background-color: #f1f8e9; /* Light green-yellow background on hover */
}

.service-card.travel:hover {
  background-color: #ede7f6; /* Light purple background on hover */
}
.custom-bg {
  background-image: url("../images/Home_Join_Us_BG.png");
  background-size: cover; /* Adjusted to cover the area */
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  height: 60vh; /* Full viewport height for the section */
}
.card-hover {
  background-color: #396c031a;
}

.hover\:card-hover:hover {
  background-color: #396c031a;
}

.hover\:card-default:hover {
  background-color: white;
}
.main-color {
  background: #52b955;
}

.boder-main-color {
  border: 1px solid #52b955;
}
.learn-about-us-section {
  background-color: #f0f5ff; /* Adjust as needed */
}

.service-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  background-color: #054752 !important;
}

.service-card:hover h3 {
  color: #4caf4f !important;
}

.service-card:hover p {
  color: #ffffff !important;
}

.service-card:hover a {
  color: #abc9ac !important;
  opacity: 50%;
  font-weight: bold;
}

.service-card:hover span {
  color: #ffffff;
}
.bg-service-section {
  background-image: url("../images/Home_Service_Section.png");
  background-size: cover;
  background-position: center;
}

/* Service.css */

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.popup-content {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  padding: 20px;
  position: relative;
}

.popup-content img {
  cursor: pointer;
}
/* ************* */
/* Modal Overlay */
.fixed.inset-0 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50; /* Ensure modal is on top */
}

/* Modal Content */
.relative.bg-white.p-6.rounded-lg.shadow-lg.max-w-lg.mx-auto {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Close Button */
.absolute.top-4.right-4 {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.text-gray-400 {
  color: #9ca3af;
}

.text-gray-900 {
  color: #111827;
}

.hover:bg-gray-200 {
  transition: background-color 0.2s;
}

.hover:text-gray-900 {
  transition: color 0.2s;
}

/* Flex and Centering */
.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

/* Cursor Pointer */
.cursor-pointer {
  cursor: pointer;
}
.text-black {
  color: #000 !important; /* Ensure this is set to black */
}
